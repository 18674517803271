<template>
  <div class="profit">
    <el-form ref="form" :model="formData" label-width="150px">
      <div class="title">成本核算</div>
      <div class="group">
        <div class="line">
          <div class="line2">
            <el-form-item class="star" label="" prop="cgdj" label-width="0">
              <el-input
                v-model.trim="formData.cgdj"
                placeholder="请输入采购单价含税价格(13%)"
                @input="formatDouble(null, 'cgdj')"
              ></el-input>
              <span>元/吨</span>
            </el-form-item>
            <!-- <el-form-item style="margin-left: 20px" label-width="0">
              <el-input disabled v-model="dunPrices"></el-input>
              <span style="background-color: #f5f7fa">(13%)</span>
            </el-form-item> -->

            <el-form-item
              class="star"
              style="margin-left: 7px"
              label=""
              prop="cgdj"
              label-width="0"
            >
              <el-input
                v-model.trim="formData.rz"
                placeholder="请输入热值"
                @input="formatDouble(null, 'rz')"
              ></el-input>
              <span>大卡/吨</span>
            </el-form-item>
          </div>

          <div
            class="list"
            v-for="(item, index) in formData.freightList"
            :key="index"
          >
            <el-form-item
              class="select star"
              label=""
              prop="legalIdcard"
              label-width="0"
            >
              <el-select
                v-model="item.type"
                @change="selectTransport"
                placeholder="请选择运输类型"
              >
                <el-option label="公路运输" value="1"></el-option>
                <el-option label="铁路运输" value="2"></el-option>
              </el-select>
            </el-form-item>
            <div class="item" v-if="item.type">
              <div class="item2">
                <el-form-item
                  v-if="item.type == 1"
                  class="star"
                  label=""
                  prop="cgdj"
                  label-width="0"
                >
                  <el-input
                    v-model.trim="item.glyfdj"
                    placeholder="请输入运费单价"
                    @input="formatFloat(index, 'glyfdj')"
                  ></el-input>
                  <span>元/吨</span>
                </el-form-item>
                <el-form-item
                  label=""
                  v-if="item.type == 1"
                  prop="cgdj"
                  style="margin-left: 1px"
                  label-width="0"
                >
                  <!-- <el-input
                  disabled
                    v-model.trim="item.startAddress"
                  ></el-input> -->
                  <span class="div_back">{{
                    (item.glyfdj * (1 + 0.09)).toFixed(4)
                  }}</span>
                  <span style="background-color: #f5f7fa">(含税9%)</span>
                </el-form-item>
                <el-form-item
                  label=""
                  v-if="item.type == 1"
                  prop="cgdj"
                  style="margin-left: 1px"
                  label-width="0"
                >
                  <span class="div_back">{{
                    (item.glyfdj * (1 + 0.13)).toFixed(4)
                  }}</span>
                  <span style="background-color: #f5f7fa">(含税13%)</span>
                </el-form-item>

                <!-- <el-form-item
                  class="star"
                  style="margin-left: 7px"
                  label=""
                  prop="cgdj"
                  label-width="0"
                >
                  <el-input
                    v-model.trim="item.startAddress"
                    placeholder="请输入起始地址"
                    maxlength="100"
                    @blur="checkAddress(index, 'startAddress')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  class="star"
                  style="margin-left: 7px"
                  prop="cgdj"
                  label-width="0"
                >
                  <el-input
                    v-model.trim="item.endAddress"
                    placeholder="请输入目的地址"
                    maxlength="100"
                    @blur="checkAddress(index, 'endAddress')"
                  ></el-input>
                </el-form-item> -->
                <div style="display:flex">
                  <el-form-item
                    v-if="item.type == 2"
                    class="star"
                    style="margin-left: 1px"
                    label=""
                    prop="cgdj"
                    label-width="0"
                  >
                    <el-input
                      v-model.trim="item.tlyfdj"
                      placeholder="请输入铁路运费单价含税价格(9%)"
                      style="width:300px"
                      @input="formatFloat(index, 'tlyfdj')"
                    ></el-input>
                    <span>元/吨</span>
                  </el-form-item>
                  <el-form-item
                    label=""
                    prop="cgdj"
                    style="margin-left: 20px"
                    label-width="0"
                    v-if="item.type == 2"
                  >
                    <span class="div_back">{{
                      ((item.tlyfdj/(1+0.09))*(1+0.13)).toFixed(4)
                    }}</span>
                    <span style="background-color: #f5f7fa">(含税13%)</span>
                  </el-form-item>
                </div>

                <div style="display:flex">
                  <el-form-item
                    v-if="item.type == 2"
                    class="star"
                    prop="cgdj"
                    label-width="0"
                  >
                    <el-input
                      style="width:300px"

                      v-model.trim="item.ztfwfdj"
                      placeholder="请输入站台服务费含税价格(6%)"
                      :value="item.ztfwfdj ? '' : 0"
                      @input="formatFloat(index, 'ztfwfdj')"
                    ></el-input>
                    <span>元</span>
                  </el-form-item>

                  <el-form-item
                    label=""
                    style="margin-left: 40px"
                    prop="cgdj"
                    label-width="0"
                    v-if="item.type == 2"
                  >
                    <span class="div_back">{{
                         ((item.ztfwfdj/(1+0.06))*(1+0.13)).toFixed(4)
                    }}</span>
                    <span style="background-color: #f5f7fa">(含税13%)</span>
                  </el-form-item>
                </div>

                <!-- <el-form-item
                  label=""
                  prop="cgdj"
                  style="margin-left: 1px"
                  label-width="0"
                  v-if="item.type == 2"
                >
                  <span class="div_back">{{ (item.ztfwfdj * (1 + 0.13)).toFixed(4) }}</span>
                  <span style="background-color: #f5f7fa">(含税13%)</span>
                </el-form-item> -->
              </div>
              <el-button
                type="danger"
                v-if="index + 1 != formData.freightList.length"
                @click="delTransport(index)"
                >删除</el-button
              >
              <el-button
                type="primary"
                v-if="index + 1 == formData.freightList.length"
                class="add"
                @click="addTransport"
                >添加</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="title">
        <span>路线配置</span>
        <el-button size="mini" @click="addRoute">添加路线</el-button>
      </div> -->
      <!-- <div class="group">
        <div
          class="line-group"
          v-for="(item, index) in formData.routeLine"
          :key="'line' + index"
        >
          <p class="line-title">路线{{ `一二三四五`.charAt(index) }}</p>
          <div class="remove" v-if="index != 0" @click="removeRoute(index)">
            <i class="el-icon-close"></i>
          </div>
          <div class="col"></div>
        </div>
      </div> -->
      <div class="title">
        融资方案
        <div>
          为您提供金融服务购煤
          <div class="icon">
            <i class="el-icon-info" style="cursor: pointer"></i>
            <p>
              新疆煤炭交易中心提供供应链金融服务，
              您可前往服务大厅内查看并申请使用金融服务商品，融资成本低，线上操作简单便捷，有效解决资金周转。
            </p>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="line line2">
          <el-form-item prop="">
            <el-input
              v-model.trim="formData.rzbl"
              placeholder="请输入融资比例"
              @input="formatDouble(null, 'rzbl')"
            ></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item class="input-left" prop="rzzq">
            <el-input
              v-model.trim="formData.rzzq"
              placeholder="请输入融资周期"
              maxlength="4"
              @input="formatNumber(null, 'rzzq')"
            ></el-input>
            <span>天</span>
          </el-form-item>
          <el-form-item prop="" style="margin-left: 18px">
            <el-input
              v-model.trim="formData.rzlv"
              placeholder="请输入融资利率"
              @input="formatDouble(null, 'rzlv')"
            ></el-input>
            <span>%</span>
          </el-form-item>
        </div>
      </div>
      <div class="title">利润核算</div>
      <div class="group">
        <div class="line line2">
          <el-form-item class="star" prop="gydj">
            <el-input
              v-model.trim="formData.gydj"
              placeholder="请输入供应单价"
              @input="formatDouble(null, 'gydj')"
            ></el-input>
            <span>元/大卡</span>
          </el-form-item>
          <el-form-item class="star second" prop="cgsl">
            <el-input
              v-model.trim="formData.cgsl"
              placeholder="请输入采购数量"
              @input="formatDouble(null, 'cgsl')"
            ></el-input>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;吨 </span>
          </el-form-item>
        </div>
        <div class="line"></div>
        <!-- <div class="line">
          <el-form-item class="star text" label="是否含税：" prop="taxIncluded">
            <el-radio-group v-model="formData.taxIncluded">
              <el-radio v-if="isHasRailway" :label="1"
                >含税 (
                <div v-for="(item, index) in taxlist" :key="index">
                  <div>
                    {{ item.param_name }}{{ item.param_value }}%{{
                      index + 1 == taxlist.length ? "。" : "，"
                    }}
                  </div>
                </div>
                )
              </el-radio>
              <el-radio v-else :label="1"
                >含税 (
                <div v-for="(item, index) in taxlist" :key="index">
                  <div v-if="!item.dictLabel">
                    {{ item.param_name }}{{ item.param_value * 100 }}%{{
                      index + 3 == taxlist.length ? "。" : "，"
                    }}
                  </div>
                </div>
                )
              </el-radio>
              <el-radio :label="0">不含税</el-radio>
            </el-radio-group>
          </el-form-item>
        </div> -->
        <div class="bottom">
          <div class="left">
            <div class="group">
              <div>
                <p>全资购煤投入资金:</p>
                <h4>{{ resultData.qztrje }}</h4>
                <span>元</span>
              </div>
              <div v-if="!issumber || !isrz">
                <p>融资方案投入资金:</p>
                <h4>{{ resultData.rztrje }}</h4>
                <span>元</span>
                <p class="date">周期：</p>
                <h4>{{ resultData.rzzq }}</h4>
                <span>天</span>
              </div>
            </div>
            <div class="group-around">
              <p>
                全资购煤预计利润:
                <span class="around">{{ resultData.qzyjlr }}</span
                ><span class="mony">元</span>
              </p>
              <p v-if="!issumber || !isrz">
                融资方案预计利润:
                <span class="around">{{ resultData.rzyjlr }}</span
                ><span class="mony">元</span>
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" class="hy-btn" @click="reset"
            >一键重置</el-button
          >
          <el-button type="primary" class="hy-btn" @click="sumit"
            >一键核算</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { reqProfit, getSysDictDataByByDictType } from "@/api/hy";
export default {
  computed: {
    isrz() {
      return this.resultData.rzzq == null;
    },
  },
  data() {
    return {
      taxlist: [],
      issumber: false,
      formData: {
        rz: "",
        cgdj: "",
        rzzq: "",
        rzbl: "",
        // taxIncluded: "A",
        gydj: "",
        rzlv: "",
        freightList: [
          {
            type: "",
            tlyfdj: "",
            glyfdj: "", //运费单价
            ztfwfdj: "", //站台服务费
          },
        ],
      },

      resultData: {
        qzyjlr: "",
        rztrje: "0",
        rzzq: "0",
        rzlv: "0",
        interest: "0",
        qztrje: "0",
      },
      isHasRailway: false,
      isClick: true,
    };
  },
  mounted() {
    this.getSysDictDataByByDictType();
  },
  computed: {
    dunPrices() {
      return (this.formData.cgdj * (1 + 0.13)).toFixed(4);
    },
  },
  methods: {
    checkAddress(index) {
      // if (
      //   this.formData.freightList[index].startAddress &&
      //   this.formData.freightList[index].endAddress
      // ) {
      //   if (
      //     this.formData.freightList[index].startAddress ==
      //     this.formData.freightList[index].endAddress
      //   ) {
      //     this.$message({
      //       message: "起始地址和目的地址不能为同一地址",
      //       type: "warning",
      //     });
      //   }
      // }
    },
    delTransport(index) {
      this.formData.freightList.splice(index, 1);
      this.selectTransport();
    },
    getSysDictDataByByDictType() {
      let config = {
        dictType: "profit_set",
        pageNum: 1,
        pageSize: 10,
      };
      getSysDictDataByByDictType(config).then((res) => {
        let list = res.data.list;
        console.log(list);

        list.forEach((item) => {
          item.dictValue = JSON.parse(item.dictValue);
          if (item.dictLabel == "tlyffpsd" || item.dictLabel == "ztfpsd") {
            item.dictValue.dictLabel = true;
          } else {
            item.dictValue.dictLabel = false;
          }
          this.taxlist.push(item.dictValue);
        });
        console.log(this.taxlist);
      });
    },
    addTransport() {
      let obj = {
        type: "",
        glyfdj: "", //运费单价
        ztfwfdj: "", //站台服务费
        tlyfdj: "",
      };
      this.formData.freightList.push(obj);
      console.log(this.formData);
    },
    InputChang() {
      this.$forceUpdate();
    },
    selectTransport() {
      console.log(this.formData.freightList);
      this.isHasRailway = this.formData.freightList.some((item) => {
        if (item.type == 1) {
          return true;
        }
      });
    },
    formatDouble(index, name) {
      var _N = 4;
      let _NUM = 5;
      // eslint-disable-next-line no-useless-escape
      this.formData[name] = this.formData[name].replace(/[^\d.]/g, ""); //清除非 数字和小数点的字符
      this.formData[name] = this.formData[name].replace(/\.{2,}/g, "."); //清除第二个小数点
      this.formData[name] = this.formData[name].replace(/^\./g, ""); //验证第一个字符是数字而不是字符
      this.formData[name] = this.formData[name]
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // eslint-disable-next-line no-useless-escape
      this.formData[name] = this.formData[name].replace(
        /^(\-)*(\d+)\.(\d{4}).*$/,
        "$1$2.$3"
      ); //保留4位小数
      this.formData[name] =
        this.formData[name].indexOf(".") > 0
          ? this.formData[name].split(".")[0].substring(0, _NUM) +
            "." +
            this.formData[name].split(".")[1]
          : this.formData[name].substring(0, _NUM); //限制只能输入_NUM = 5位正整数

      if (name == "rzbl" || name == "rzlv") {
        this.formData[name] =
          this.formData[name].match(/^\d*(\.?\d{0,1}[1-9]{0,1})/g)[0] || "";
      }
    },
    formatNumber(index, name) {
      console.log(index);
      console.log(name);
      if (index == null) {
        if (this.formData[name] == 0) {
          this.formData[name] = "";
        }
        this.formData[name] = this.formData[name].match(/^\d*/g)[0] || "";
      } else {
        this.formData.freightList[index][name] =
          this.formData.freightList[index][name].match(/^\d*/g)[0] || "";
      }
    },
    formatFloat(index, name) {
      if (index == null) {
        if (this.formData[name] * 100 > 99999 * 100) {
          if (this.formData[name].indexOf(".") > 0) {
            this.formData[name] = this.formData[name].substring(0, 7);
          } else {
            this.formData[name] = this.formData[name].substring(0, 5);
          }
        }
        console.log(this.formData[name]);

        this.formData[name] =
          this.formData[name].match(/^\d*(\.?\d{0,23})/g)[0] || "";

        if (this.formData[name].indexOf(".") > 0) {
          this.formData[name] = this.formData[name].substring(
            0,
            this.formData[name].indexOf(".") + 5
          );
        }
        if (name == "rzbl") {
          if (this.formData[name] == "00") {
            this.formData[name] = this.formData[name].substring(0, 1);
          }
          if (this.formData[name] > 100) {
            this.formData[name] = 100;
            this.$message({
              message: "融资比例不能大于100",
              type: "warning",
            });
          }
        } else {
          if (this.formData[name] == 0) {
            // this.formData[name] = "";
          }
        }
      } else {
        this.formData.freightList[index][name] =
          this.formData.freightList[index][name].match(
            /^\d*(\.?\d{0,23})/g
          )[0] || "";
        if (this.formData.freightList[index][name].indexOf(".") > 0) {
          this.formData.freightList[index][name] = this.formData.freightList[
            index
          ][name].substring(
            0,
            this.formData.freightList[index][name].indexOf(".") + 3
          );
        }

        if (
          this.formData.freightList[index][name] == "0" ||
          this.formData.freightList[index][name] == "."
        ) {
          this.formData.freightList[index][name] = "";
        }
        console.log(this.formData.freightList[index][name]);
        if (this.formData.freightList[index][name] * 100 > 99999 * 100) {
          if (this.formData.freightList[index][name].indexOf(".") > 0) {
            this.formData.freightList[index][name] = this.formData.freightList[
              index
            ][name].substring(0, 7);
          } else {
            this.formData.freightList[index][name] = this.formData.freightList[
              index
            ][name].substring(0, 5);
          }
        }
      }
    },
    addRoute() {
      this.formData.routeLine.push({});
    },
    removeRoute(index) {
      this.formData.routeLine.splice(index, 1);
    },
    reset() {
      location.reload();
    },
    sumit() {
      if (this.isClick) {
        if (!this.formData.cgdj) {
          this.$message({
            message: "请输入采购单价！",
            type: "warning",
          });
          return false;
        }
        if (!this.formData.rz) {
          this.$message({
            message: "请输入热值！",
            type: "warning",
          });
          return false;
        }

        let arr = this.formData.freightList;
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].type) {
            this.$message({
              message: "请选择运输类型！",
              type: "warning",
            });
            return false;
          }
          if (arr[i].type == "1") {
            if (!arr[i].glyfdj) {
              this.$message({
                message: "请输入公路运费单价！",
                type: "warning",
              });
              return false;
            }
          }

          if (arr[i].type == "2") {
            if (!arr[i].tlyfdj) {
              this.$message({
                message: "请输入铁路运费单价！",
                type: "warning",
              });
              return false;
            }
          }

          // if (!arr[i].startAddress) {
          //   this.$message({
          //     message: "请输入起始地",
          //     type: "warning",
          //   });
          //   return false;
          // }
          // if (!arr[i].endAddress) {
          //   this.$message({
          //     message: "请输入目的地",
          //     type: "warning",
          //   });
          //   return false;
          // }
          // if (arr[i].startAddress == arr[i].endAddress) {
          //   this.$message({
          //     message: "起始地址和目的地址不能为同一地址",
          //     type: "warning",
          //   });
          //   return false;
          // }
          if (arr[i].type == 2) {
            if (!arr[i].ztfwfdj) {
              this.$message({
                message: "请输入站台服务费！",
                type: "warning",
              });
              return false;
            }
          }
        }
        if (this.formData.rzbl) {
          if (!this.formData.rzzq) {
            this.$message({
              message: "请输入融资周期！",
              type: "warning",
            });
            return false;
          }
          if (!this.formData.rzlv) {
            this.$message({
              message: "请输入融资利率！",
              type: "warning",
            });
            return false;
          }
        }
        if (this.formData.rzzq) {
          if (!this.formData.rzbl) {
            this.$message({
              message: "请输入融资比例！",
              type: "warning",
            });
            return false;
          }
          if (!this.formData.rzlv) {
            this.$message({
              message: "请输入融资利率！",
              type: "warning",
            });
            return false;
          }
        }

        if (this.formData.rzlv) {
          if (!this.formData.rzzq) {
            this.$message({
              message: "请输入融资周期！",
              type: "warning",
            });
            return false;
          }
          if (!this.formData.rzbl) {
            this.$message({
              message: "请输入融资比例！",
              type: "warning",
            });
            return false;
          }
        }
        if (!this.formData.gydj) {
          this.$message({
            message: "请输入供应单价！",
            type: "warning",
          });
          return false;
        }
        if (!this.formData.cgsl) {
          this.$message({
            message: "请输入采购数量！",
            type: "warning",
          });
          return false;
        }
        if (!this.formData.cgsl) {
          this.$message({
            message: "请输入采购数量！",
            type: "warning",
          });
          return false;
        }
        // if (this.formData.taxIncluded == "A") {
        //   this.$message({
        //     message: "请选择是否含税！",
        //     type: "warning",
        //   });
        //   return false;
        // }
        this.formData.freightList.forEach((item) => {
          if (!item.ztfwfdj) {
            item.ztfwfdj = 0;
          }
        });
        this.isClick = false;
        let timer = setTimeout(() => {
          this.isClick = true;
          clearTimeout(timer);
        }, 2000);
        reqProfit(this.formData).then((ret) => {
          if (ret.code == 0) {
            this.resultData = ret.data;
            this.issumber = true;
          } else {
            this.$message.error(ret.message);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  text-align: right;
}
.profit {
  width: 1195px;
  margin: 14px auto;
  background: white;
  border: 1px solid #e5e5e5;
  border-top: none;
  &::before,
  &::after {
    display: table;
    content: "";
  }
  .el-input {
    width: 80%;
  }
}
.el-input__inner {
  border: 0;
}
.title {
  font-size: 18px;
  font-weight: bold;
  font-family: PingFang SC;
  padding: 14px 15px;
  border: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  div {
    font-size: 14px;
    font-weight: normal;
    margin-left: 10px;
    display: flex;
    div.icon {
      position: relative;
      p {
        padding: 20px;
        position: absolute;
        display: none;
        background: #ffffff;
        width: 400px;
        z-index: 100;
        top: 19px;
        left: 19px;
        border-radius: 8px;
        box-shadow: 0px 0px 14px 2px rgba(45, 45, 45, 0.09);
      }
      i {
        color: #333333;
        font-size: 19px;
      }
    }
    div.icon:hover {
      p {
        display: flex;
      }
    }
  }
}
.btn-wrap {
  text-align: center;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  .left {
    // display: flex;
    p {
      margin-bottom: 10px;
    }
    .group + .group {
      margin-left: 40px;
    }
    .group div {
      font-size: 14px;
      display: flex;
      align-items: baseline;
      p {
        margin-right: 30px;
      }
      p.date {
        margin-left: 30px;
        margin-right: 0;
      }
      span {
        color: #d0111a;
      }
      h4 {
        font-size: 30px;
        vertical-align: baseline;
        color: #d0111a;
      }
    }
    .group-around {
      padding: 32px 39px;
      .mony {
        color: #d0111a;
        //  vertical-align: baseline;
        //  font-weight: bold;
      }
      .around {
        font-size: 30px;
        font-weight: bold;
        vertical-align: baseline;
        color: #d0111a;
      }
      p {
        height: 40px;
        line-height: 36px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          // width: 134px;
          height: 42px;
          line-height: 42px;
          // background: #ffffff;
          // border: 1px solid #d2d2d2;
          border-radius: 2px;
          margin: 0 10px;
        }
      }
    }
  }
}
.route-line {
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: bold;
  }
  .el-button {
    margin-left: 20px;
  }
}
.line-title {
  font-size: 14px;
  margin-bottom: 10px;
}
.line-group {
  padding: 10px;
  border: 1px solid #ebebeb;
  margin: 10px 0;
  position: relative;
  .remove {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
.group {
  padding: 32px 15px;
  background: white;
}
.line {
  .star {
    display: flex;
    align-items: center;
  }
  .star::before {
    content: "*";
    color: #ff0000;
    font-size: 14px;
    margin-right: 10px;
    padding-left: 5px;
  }
  align-items: center;

  .el-input {
    width: 300px;
  }
  .select .el-input {
    width: 209px;
    span {
      padding-right: 0;
    }
  }
  .el-cascader {
    width: 275px;
  }
}

.line2 {
  display: flex;
  .input-left {
    margin-left: 18px;
  }
  .second {
    margin-left: 30px;
  }
  .text {
    margin-left: 15px;
    .el-form-item__content {
      border: 0;
    }
    .el-form-item__label {
      width: 86px !important;
      span {
        // border: 1px solid blue;
      }
    }
  }
}
.list {
  display: flex;
  align-items: baseline;
  .item {
    display: flex;
    align-items: baseline;
    .el-form-item__content {
      display: flex;
      .el-input {
        width: 195px;
      }
    }
    .item2 {
      // width: 710px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  button {
    // width: 98px;
    // height: 34px;
    padding: 0 20px;
    border-radius: 4px;
    line-height: 34px;
    // padding: 0;
    margin-bottom: 22px;
    margin-left: 14px;
  }
  button.add {
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
  }
}
::v-deep .el-radio {
  display: flex;
  margin-top: 8px;
  .el-radio__input {
    padding-right: 5px !important;
  }
  .el-radio__label {
    display: flex;
  }
}
::v-deep .el-radio-group {
  width: 300px;
}
.div_back {
  display: inline-block;
  width: 190px;
  background-color: #f5f7fa;
  padding-left: 5px;
}
</style>
